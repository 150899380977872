import { Observable, BehaviorSubject } from 'rxjs';
import { map, first, share } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BackendService } from '@services/api/backend/backend.service';
import { InstallationEntity } from '@entities/installation.entity';
import { InstallationListEntity } from '@entities/installation-list.entity';
import { InstallationImageEntity } from '@entities/installation-image.entity';
import { InstallationImageListEntity } from '@entities/installation-image-list.entity';
import { InstallationEventEntity } from '@entities/installation-event.entity';
import { InstallationEventListEntity } from '@entities/installation-event-list.entity';
import { IInstallationEventsPaginatedRequestContext } from '@interfaces/installation-events-paginated-request-context.interface';
import { BaseDeleteResponse } from '@entities/base-delete-response.entity';
import { SiloEntity } from '@entities/silo.entity';
import { SiloListEntity } from '@entities/silo-list.entity';
import { IDataExportRequestContext } from '@interfaces/data-export-request-context.interface';
import {
  ILiteInstallationsExportRequestContext,
  IPlusInstallationsExportRequestContext,
} from '@interfaces/all-installations-export-request-context.interface';

@Injectable({
  providedIn: 'root',
})
export class BEInstallationsService {
  public installationsList$: BehaviorSubject<InstallationEntity[]> = new BehaviorSubject<
    InstallationEntity[]
  >(undefined);

  constructor(private be: BackendService, private localStorageService: LocalStorageService) {}

  public getInstallationById(id: string): Observable<InstallationEntity> {
    return this.be.get(`/installation/${id}`, InstallationEntity).pipe(share());
  }

  public checkClaimableInstallation(registrationCode: string): Observable<InstallationEntity> {
    return this.be
      .get(`/claimable/installation/${registrationCode}`, InstallationEntity)
      .pipe(share());
  }

  public checkInstallationReferenceInUse(
    encryption: 'qr' | 'xml',
    encrypted: string
  ): Observable<InstallationEntity> {
    return this.be
      .get(`/installation/reference/${encryption}/${encrypted}`, InstallationEntity)
      .pipe(share());
  }

  public claimInstallationByRegistrationCode(
    registrationCode: string
  ): Observable<InstallationEntity> {
    return this.be
      .post(`/claim/installation/${registrationCode}`, {}, InstallationEntity)
      .pipe(share());
  }

  public list(companyId: string): Observable<InstallationEntity[]> {
    let endpointPath = '/installations';
    if (this.localStorageService.userIsSuperAdmin && companyId) {
      endpointPath += `/${companyId}`;
    }
    return this.be.get(endpointPath, InstallationListEntity).pipe(
      first(),
      map((response) => response.installations)
    );
  }

  public listAllPlus(): Observable<InstallationEntity[]> {
    return this.be.get('/all/installations/plus', InstallationListEntity).pipe(
      first(),
      map((response) => response.installations)
    );
  }

  public exportPlus(
    payload: IPlusInstallationsExportRequestContext
  ): Observable<InstallationEntity[]> {
    return this.be.post('/all/installations/plus/export', payload, InstallationListEntity).pipe(
      first(),
      map((response) => response.installations)
    );
  }

  public listAllLite(): Observable<InstallationEntity[]> {
    return this.be.get('/all/installations/lite', InstallationListEntity).pipe(
      first(),
      map((response) => response.installations)
    );
  }

  public exportLite(
    payload: ILiteInstallationsExportRequestContext
  ): Observable<InstallationEntity[]> {
    return this.be.post('/all/installations/lite/export', payload, InstallationListEntity).pipe(
      first(),
      map((response) => response.installations)
    );
  }

  public listImages(installationId: string): Observable<InstallationImageEntity[]> {
    return this.be
      .get(`/installation/${installationId}/list-only/images`, InstallationImageListEntity)
      .pipe(
        first(),
        map((response) => response.images)
      );
  }

  public listEvents(
    installationId: string,
    payload: IInstallationEventsPaginatedRequestContext
  ): Observable<InstallationEventEntity[]> {
    return this.be
      .post(`/installation/${installationId}/events`, payload, InstallationEventListEntity)
      .pipe(
        first(),
        map((response) => response.events)
      );
  }

  public uploadImage(image: InstallationImageEntity): Observable<InstallationImageEntity> {
    return this.be.post(`/installation/image`, image, InstallationImageEntity).pipe(share());
  }

  public getInstallationImage(installationId: string): Observable<InstallationImageEntity> {
    return this.be.get(`/installation/image/${installationId}`);
  }

  public deleteNonCanvasImage(imageId: string): Observable<BaseDeleteResponse> {
    return this.be.delete(`/installation/image/${imageId}`, BaseDeleteResponse).pipe(share());
  }

  public create(installation: InstallationEntity): Observable<InstallationEntity> {
    return this.be.put('/installation', installation, InstallationEntity).pipe(share());
  }

  public update(installation: InstallationEntity): Observable<InstallationEntity> {
    return this.be.post('/installation', installation, InstallationEntity).pipe(share());
  }

  public updateControls(installation: InstallationEntity): Observable<InstallationEntity> {
    return this.be.post('/installation/controls', installation, InstallationEntity).pipe(share());
  }

  public emptyStructure(installationId: string): Observable<InstallationEntity> {
    return this.be
      .delete(`/installation/${installationId}/structure`, InstallationEntity)
      .pipe(share());
  }

  public createShortLink(installationId: string): Observable<InstallationEntity> {
    return this.be
      .put(`/installation/${installationId}/shortlink`, {}, InstallationEntity)
      .pipe(share());
  }

  public unlink(installationId: string): Observable<InstallationEntity> {
    return this.be
      .post(`/installation/${installationId}/unlink`, {}, InstallationEntity)
      .pipe(share());
  }

  public liteDataExcel(
    installationId: string,
    payload: IDataExportRequestContext
  ): Observable<SiloEntity[]> {
    return this.be.post(`/installation/${installationId}/excel`, payload, SiloListEntity).pipe(
      first(),
      map((response) => response.silos)
    );
  }

  public delete(installationId: string): Observable<InstallationEntity> {
    return this.be.delete(`/installation/${installationId}`, InstallationEntity).pipe(share());
  }

  public updateState(installationId: string, state: number) {
    return this.be.post(`/installation/${installationId}/state`, { state }).pipe(share());
  }

  public updateLocationState(installationId: string, locationState: number) {
    return this.be
      .post(`/installation/${installationId}/location-state`, { locationState })
      .pipe(share());
  }

  public mapListAll(): Observable<any> {
    return this.be.get('/all/installations/locations').pipe(share());
  }
}
