import { buildChecksum } from '@utils/utils';
import { Expose } from 'class-transformer';
import { BaseEntity } from './base.entity';

export class RegisterActionTokenDataEntity extends BaseEntity {
  @Expose()
  public dataProvider: number;
}

export class RegisterPlusActionTokenDataEntity extends RegisterActionTokenDataEntity {
  @Expose()
  public checkSum: string;

  @Expose()
  public desktopKey: string;

  @Expose()
  public reference: string;

  @Expose()
  public encryptedDesktopKey: string;

  get decryptedDesktopKey(): string {
    return this.base64DecodedValue(this.encryptedDesktopKey);
  }

  @Expose()
  public encryptedReference: string;

  get decryptedReference(): string {
    return this.base64DecodedValue(this.encryptedReference);
  }

  get valid(): boolean {
    return !!this.reference && !!this.desktopKey;
  }

  get validQR(): boolean {
    return !!this.encryptedReference && !!this.encryptedDesktopKey;
  }

  matchesCheckSum(checkSum: string): boolean {
    return checkSum === buildChecksum(this.decryptedReference + this.decryptedDesktopKey);
  }
}

export class RegisterLiteActionTokenDataEntity extends RegisterActionTokenDataEntity {
  @Expose()
  public registrationCode: string;

  @Expose()
  public email: string;
}
